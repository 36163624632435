import React, { useEffect } from 'react';
import './App.css';

function App() {
  useEffect(() => {
    window.location.href = 'https://www.prerequisite.com.au/';
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        {/* <h1>Prerequisite Capital</h1> */}
      </header>
    </div>
  );
}

export default App;
